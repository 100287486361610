import Link from 'next/link'
import { useEffect, useState } from 'react'
import LanguageDropdown from './LanguageDropdown'

export default function Header({ lng = undefined }: { lng: string | undefined }) {
  const [selectUserLang, setSelectUserLang] = useState('en')
  const [bgBackDropFilter, setBgBackDropFilter] = useState('backdrop-blur-none')
  const listenScrollEvent = () => {
    if (window.scrollY > 100) {
      setBgBackDropFilter('backdrop-blur')
    } else {
      setBgBackDropFilter('backdrop-blur-none')
    }
  }

  useEffect(() => {
    window.addEventListener('scroll', listenScrollEvent)
  }, [])

  useEffect(() => {
    if (lng === 'ko') {
      setSelectUserLang('ko')
    } else if (lng === 'ja') {
      setSelectUserLang('ja')
    } else {
      setSelectUserLang('en')
    }
  }, [lng])

  return (
    <header
      className={`fixed top-0 z-[9999] h-[70px] w-screen transition-colors lg:h-[100px] ${bgBackDropFilter} px-4 py-3 sm:px-8`}
    >
      <div className={`flex h-full w-full select-none items-center justify-between`}>
        <Link href={'/'}>
          <img
            src={`/assets/images/logo/wkm_logo_dark.svg`}
            className={`h-[40px] w-auto lg:h-[55px]`}
            alt={`walkmining`}
          />
        </Link>
        <LanguageDropdown lng={selectUserLang} />
        {/* <motion.a
          href={`https://bit.ly/3WuxcBA`}
          target={`_blank`}
          whileHover={{ scale: 1.1 }}
          transition={{ type: 'spring', stiffness: 1000, damping: 10 }}
          className={`button-frame flex items-center space-x-2 rounded-[100px] px-6 py-2 xl:px-8 xl:py-4`}
        >
          <ArrowTopRightOnSquareIcon className={`w-[16px] xl:w-[23px]`} />
          <span className={`hidden whitespace-nowrap text-[23px] font-semibold text-[#343A40] xl:block`}>
            {btnExplorerText}
          </span>
          <span className={`block whitespace-nowrap text-[15px] font-semibold text-[#343A40] xl:hidden`}>
            {btnExplorerSimpleText}
          </span>
        </motion.a> */}
      </div>
    </header>
  )
}
